@use 'sass:math';
@import "./src/styles/Library";
/* App */

/*
    Project customizations here
    Overides default dash styles
*/

 .status-builds-complete {
    background-color: $greenHl;
    color: $light0;
}

.status-builds-error {
    background-color: $orangeHl;
    color: $light0;
}

.status-builds-pending {
    background-color: $light4;
    color: $light0;
}

.status-builds-running {
    background-color: $blueHl;
    color: $light0;
}

.status-builds-building {
    background-color: $purpleHl;
    color: $light0;
}
